<template>
    <div class="price-block">
        <h6>
            <span v-if="showFromText" class="mr-5">{{ $t('common.from') }}</span>
            <span v-if="preparedPriceWithDiscount != preparedPrice" class="text-line-through">
                €{{ preparedPrice }}
            </span>
        </h6>
        <div class="price__wrapper f-column-align-end">
            <h2>€{{ preparedPriceWithDiscount }}</h2>
            <h4 v-if="showSubtitle && !isUnique">{{ subtitleText }}</h4>
        </div>
    </div>
</template>

<script>
import { translation } from '@/helpers/TranslationHelper';

export default {
    name: 'PriceBlock',
    props: {
        isUnique: {
            type: Boolean,
            default: false,
        },
        price: {
            type: [Number, String],
            default: 0,
        },
        priceWithDiscount: {
            type: [Number, String],
            default: 0,
        },
        showSubtitle: {
            type: Boolean,
            default: true,
        },
        showFromText: {
            type: Boolean,
            default: true,
        },
        subtitleText: {
            type: String,
            default: translation('common.forADay'),
        },
    },
    computed: {
        preparedPrice() {
            return typeof this.price === 'number' ? this.price.toFixed(2) : this.price;
        },
        preparedPriceWithDiscount() {
            return typeof this.priceWithDiscount === 'number'
                ? this.priceWithDiscount.toFixed(2)
                : this.priceWithDiscount;
        },
    },
};
</script>

<style lang="scss" scoped>
.price-block {
    @include column-align-end;

    h2 {
        font-size: $font-30;
    }

    h4 {
        font-size: $font-16;
    }

    h6 {
        font-size: $font-14;

        .text-line-through {
            color: $grey-static;

            text-decoration: line-through;
        }
    }
}
</style>
