<template>
    <div class="cart-section">
        <h5>{{ title }}</h5>
        <slot />
    </div>
</template>

<script>
    export default {
        name: 'CartSection',
        props: {
            title: {
                type: String,
                default: '',
            },
        },
    };
</script>

<style lang="scss" scoped>
    .cart-section {
        padding: 25px;

        border-bottom: 1px solid $grey-line;

        h5 {
            margin-bottom: 14px;
        }
    }
</style>
