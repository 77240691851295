<template>
    <div class="meal-kit-card" :class="cardClasses">
        <div class="card-header" :style="backgroundStyles(image.inner ? image.inner : image.block)">
            <!-- discount -->
            <div class="discount" v-if="discountAmount && discountType && !isRecommended">
                <h2>{{ getDiscountDisplay(discountAmount, discountType) }}</h2>
            </div>

            <!-- admin-panel -->
            <div v-if="isAdminView && !isIndividual" class="admin-panel">
                <CRButton class="btn" size="xx-small" :pattern="isHidden ? 'outline' : 'fill'"
                    @click.stop="hideMealKit">
                    {{ isHidden ? $t('buttons.show') : $t('buttons.hide') }}
                </CRButton>
                <CRButton size="xx-small" color="danger" @click.stop="deleteMealKit">
                    {{ $t('buttons.delete') }}
                </CRButton>
            </div>

            <!-- recommended-info -->
            <div class="recommended-info" v-if="isRecommended && !isIndividual">
                <CRTag class="kcal-amount">{{ kcalAmount }} {{ $t('common.kcalDay') }}</CRTag>
                <CRTag> {{ mealsAmount }} {{ $t(mealLabel) }} </CRTag>
            </div>

            <!-- header-bottom -->
            <div class="header-bottom" :class="{ 'align-end': !isVegetarian }">
                <CRTag v-if="isVegetarian" type="secondary">
                    {{ $t('common.havePescatarian') }}
                </CRTag>
                <CRButton v-if="!isIndividual && !isAdminView" add-to-cart :active="isAddedToCart"
                    @click.stop="$emit('openCart')" />
            </div>
        </div>

        <!-- card-footer -->
        <div v-if="!$slots['price']" class="card-footer">
            <div class="item-left">
                <CRTag v-if="mealsAmount && !isRecommended">{{ mealsAmount }} {{ $t(mealLabel) }}</CRTag>
                <h3>{{ !isIndividual ? name : $t('individualOrder.title') }}</h3>
                <CRButton v-if="isIndividual && isRecommended" class="learn-more">
                    {{ $t('buttons.makeRequest') }}
                </CRButton>
            </div>
            <PriceBlock v-if="!isRecommended && !isIndividual" :price="price" :show-from-text="!isUnique"
                :price-with-discount="getPriceWithDiscount(price, discountAmount, discountType)"
                :show-subtitle="!isUnique && !isIndividual && !isGift" />
            <PriceBlock v-if="!isRecommended && isIndividual" priceWithDiscount="13,08" price="13,08"
                :showSubtitle="false" />
        </div>

        <slot name="price" />

        <!-- shadow if isHidden -->
        <div v-if="isAdminView && isHidden" class="shadow" />
    </div>
</template>

<script>
import CRTag from '@/components/Common/Tags/CRTag.vue';
import CRButton from '@/components/Common/Buttons/CRButton.vue';
import { backgroundStyles } from '@/helpers/CssHelper';
import { getDiscountDisplay } from '@/helpers/MealKitHelper';
import { getPriceWithDiscount } from '@/helpers/CartHelper';
import PriceBlock from '@/components/MealKits/PriceBlock.vue';

export default {
    name: 'MealKitCard',
    components: { CRTag, CRButton, PriceBlock },
    props: {
        id: {
            type: Number,
            default: 0,
        },
        name: {
            type: String,
            default: '',
        },
        mealsAmount: {
            type: [Number, String],
            default: 0,
        },
        price: {
            type: Number,
            default: 0,
        },
        discountType: {
            type: String,
            default: null,
        },
        discountAmount: {
            type: Number,
            default: null,
        },
        kcalAmount: {
            type: Number,
            default: null,
        },
        image: {
            type: String,
            default: '',
        },
        isVegetarian: {
            type: Boolean,
            default: false,
        },
        isAddedToCart: {
            type: Boolean,
            default: false,
        },
        isIndividual: {
            type: Boolean,
            default: false,
        },
        isUnique: {
            type: Boolean,
            default: false,
        },
        isGift: {
            type: Boolean,
            default: false,
        },
        isRecommended: {
            type: Boolean,
            default: false,
        },
        isAdminView: {
            type: Boolean,
            default: false,
        },
        isHidden: {
            type: Boolean,
            default: false,
        },
        imageHeight: {
            type: Number,
            default: 133,
        },
    },
    emits: ['openCart', 'delete', 'hide'],
    data() {
        return {
            backgroundStyles,
            getDiscountDisplay,
            getPriceWithDiscount,
        };
    },
    computed: {

        mealLabel() {
            return `common.${this.mealsAmount === 1 ? 'meal' : this.mealsAmount > 1 && this.mealsAmount < 10 ? 'meals' : 'meals_plural'}`;
        },

        cardClasses() {
            return [
                this.isVegetarian && `meal-kit-card-vegetarian`,
                this.isIndividual && `meal-kit-card-individual`,
                this.isUnique && `meal-kit-card-unique`,
                this.isGift && `meal-kit-card-gift`,
                this.isRecommended && `meal-kit-card-recommended`,
                this.isAdminView && `meal-kit-card-admin`,
            ];
        },
        cardHeaderHeight() {
            return `${this.imageHeight}px`;
        },
    },
    methods: {
        deleteMealKit() {
            this.$emit('delete', {
                id: this.id,
                isUnique: this.isUnique,
                name: this.name,
            });
        },

        hideMealKit() {
            this.$emit('hide', {
                id: this.id,
                data: {
                    isHidden: !this.isHidden,
                },
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.shadow {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;

    background: rgba(16, 16, 16, 0.15);

    z-index: $index-100;
}

.meal-kit-card {
    @include column-align-stretch-justify-between;

    box-shadow: $box-shadow-dark;
    border-radius: $br-10;

    cursor: pointer;

    position: relative;

    overflow: hidden;

    transition: all 0.2s linear;

    background-color: $white;

    .card-header {
        @include column-align-stretch-justify-end;

        width: 100%;
        height: v-bind(cardHeaderHeight);

        background-image: url('../../assets/images/backgrounds/empty-mealKit-bg.png');

        padding: 0 12px 8px 12px;

        .header-bottom {
            @include row-align-end-justify-between;

            &.align-end {
                @include row-align-end-justify-end;
            }
        }

        .discount {
            position: absolute;
            top: 0;
            left: 0;

            padding: 5px 17px;

            box-shadow: $box-shadow-dark;
            background-color: $white;

            border-bottom-right-radius: $br-10;

            h2 {
                color: $danger;
            }
        }
    }

    .card-footer {
        @include row-align-end-justify-between;
        width: 100%;

        padding: 15px;

        .item-left {
            @include column-align-start;
            max-width: calc(100% - 140px);

            overflow: hidden;
            text-overflow: ellipsis;

            h3 {
                width: 100%;
                margin-top: 5px;
            }
        }
    }
}

// individual
.meal-kit-card-individual {
    border: 2px solid $primary;
}

// unique
.meal-kit-card-unique {
    border: 2px solid $orange;
}

// gift
.meal-kit-card-gift {
    border: 2px solid $yellow;
}

// recommended
.meal-kit-card-recommended {
    .card-header {
        @include column-align-stretch-justify-between;

        padding-top: 16px;

        .recommended-info {
            @include column-align-start-justify-between;

            .kcal-amount {
                margin-bottom: 5px;
            }
        }
    }

    .card-footer {
        width: 100%;

        .item-left {
            max-width: 100%;
            width: 100%;

            .learn-more {
                margin-top: 10px;
                width: 100%;
            }
        }
    }
}

// admin
.meal-kit-card-admin {
    .card-header {
        @include column-align-stretch-justify-between;
        padding-top: 7px;
    }

    .admin-panel {
        z-index: $index-200;

        @include column-align-end-justify-between;

        .btn {
            margin-bottom: 10px;
        }
    }
}

@include media($lg) {
    .meal-kit-card:hover {
        transform: scale(1.05);
    }

    .meal-kit-card-recommended:hover {
        transform: scale(1);
    }
}
</style>
