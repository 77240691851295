<template>
    <div class="prices-page" :class="{ 'empty-page': !allShownMealKits.length }">
        <div class="prices-page__wrapper" v-if="allShownMealKits.length">
            <h2>{{ $t('navbar.prices') }}</h2>
            <MealKitCard
                class="meal-card"
                @open-cart="openCart(mealKit)"
                v-for="mealKit in allShownMealKits"
                :key="mealKit.id"
                :id="mealKit.id"
                :name="$filters.getTranslationByKey(mealKit.name)"
                :meals-amount="mealKit?.mealsAmount"
                :is-vegetarian="mealKit?.isVegetarian"
                :discount-type="mealKit.discount?.type"
                :discount-amount="mealKit.discount?.amount"
                :image="mealKit.pictures[0]?.picturePath"
                :is-unique="mealKit?.isUnique"
                :is-added-to-cart="isAddedToCart(mealKit.id)"
                :image-height="140"
            >
                <template #price>
                    <CalcPriceSection :meal-kit="mealKit" @openMealKitPage="openMealKitPage(mealKit)" />
                </template>
            </MealKitCard>
        </div>

        <h2 class="text-center" v-else>{{ $t('mealKits.thereAreNoMealKits') }}</h2>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import MealKitCard from '@/components/MealKits/MealKitCard.vue';
    import CalcPriceSection from '@/components/MealKits/CalcPriceSection.vue';
    import { cartMixin } from '@/utils/mixins';
    import { ConversionEventNameEnum } from '@/utils/enums';

    export default {
        name: 'Prices',
        components: { MealKitCard, CalcPriceSection },
        mixins: [cartMixin],
        computed: {
            ...mapGetters('mealKit', ['allShownMealKits']),
        },
        methods: {
            ...mapActions('mealKit', ['getAllMealKits']),

            async openMealKitPage(mealKit) {
                if (!mealKit.isIndividual) {
                    this.$router.push({
                        name: 'mealKit',
                        params: { meal_kit_id: mealKit.id },
                    });
                } else {
                    this.$router.push({
                        name: 'individualOrderKit',
                    });
                }

                fbq('track', ConversionEventNameEnum.VIEW_CONTENT, {
                    content_ids: [mealKit.id],
                    content_name: mealKit.name,
                    content_type: 'product'
                });
            },
        },
        async created() {
            try {
                await this.getAllMealKits();
            } catch (error) {
                this.$filters.toast(error.message);
            }
        },
    };
</script>

<style lang="scss" scoped>
    .prices-page {
        padding-top: 25px;
        padding-bottom: 25px;

        h2 {
            margin-bottom: 24px;
        }

        .meal-card {
            margin-bottom: 20px;
            cursor: unset;

            &:hover {
                transform: unset;
            }
        }
    }

    @include media($md) {
        .prices-page__wrapper {
            @include row-align-start;
            flex-wrap: wrap;

            h2 {
                width: 100%;
            }

            .meal-card {
                width: 49%;

                margin-right: 1%;

                &:nth-of-type(2n) {
                    margin-right: 0;
                }
            }
        }
    }

    @include media($lg) {
        .prices-page {
            padding-top: 50px;
            padding-bottom: 30px;

            h2 {
                font-size: $font-34;
                margin-bottom: 25px;
            }

            .meal-card {
                width: 48%;

                margin-right: 4%;
            }
        }
    }

    @include media($xl) {
        .prices-page {
            .meal-card {
                width: 32%;

                &:nth-of-type(2n),
                &:nth-of-type(1n) {
                    margin-right: 2%;
                }

                &:nth-of-type(3n) {
                    margin-right: 0;
                }
            }
        }
    }
</style>
