<template>
    <div class="calc-price-section">
        <div class="title-block">
            <CRButton class="name" pattern="thin" @click="$emit('openMealKitPage')">
                {{ $filters.getTranslationByKey(mealKit.name) }}
            </CRButton>
            <CRTag>{{ mealKit.mealsAmount }} {{ $t('common.meals') }}</CRTag>
        </div>
        <CartSection class="cart-section" v-if="!mealKit.isUnique" :title="$t('common.calorieNorm')">
            <div class="btn__wrapper">
                <CRRadio
                    class="radio"
                    v-for="calorie in mealKit.calories"
                    :key="calorie.id"
                    :value="calorie"
                    v-model="form.calorie"
                >
                    {{ calorie.amount }}
                </CRRadio>
            </div>
        </CartSection>
        <CartSection class="cart-section" v-if="!mealKit.isUnique" :title="$t('cart.term')">
            <div class="btn__wrapper">
                <CRRadio
                    class="radio"
                    v-for="period in mealKit.periods"
                    :key="period.id"
                    :value="period"
                    v-model="form.period"
                >
                    {{ $filters.getTranslationByKey(period.name) }}
                </CRRadio>
            </div>
        </CartSection>
        <CartSection class="cart-section" v-if="!mealKit.isUnique" :title="$t('common.allergens')">
            <CRCheckbox
                class="radio"
                v-for="allergen in allergens"
                :key="allergen.id"
                :value="allergen"
                :checked="checkIfObjectAddedInArray('allergens', allergen)"
                @change="handelToggle(allergen, 'allergens')"
            >
                {{ renderAllergenName(true, allergen) }}
            </CRCheckbox>
        </CartSection>
        <CartSection class="cart-section additions-section" v-if="!mealKit.isUnique" :title="$t('common.additions')">
            <p>{{ $t('common.pricesShownForDays', { amount: form.period?.amount }) }}</p>
            <CRCheckbox
                class="radio"
                v-for="addition in additions"
                :key="addition.id"
                :value="addition"
                :checked="checkIfObjectAddedInArray('additions', addition)"
                @change="handelToggle(addition, 'additions')"
            >
                {{ getAdditionName(addition) }}
            </CRCheckbox>
        </CartSection>
        <PriceBlock
            class="price-block"
            :price="totalPriceWithoutDiscount"
            :price-with-discount="totalPrice"
            :show-subtitle="!mealKit.isUnique"
            :show-from-text="false"
            :subtitle-text="$t('cart.forDays', { amount: form.period?.amount })"
        />
    </div>
</template>

<script>
    import CartSection from '@/components/Cart/CartSection.vue';
    import CRCheckbox from '@/components/Common/Checkbox/CRCheckbox.vue';
    import CRButton from '@/components/Common/Buttons/CRButton.vue';
    import CRRadio from '@/components/Common/Radio/CRRadio.vue';
    import CRTag from '@/components/Common/Tags/CRTag.vue';
    import PriceBlock from '@/components/MealKits/PriceBlock.vue';
    import { getMealKitAllPrices } from '@/helpers/CartHelper';
    import { calculateMealKitPiceMixin } from '@/utils/mixins';

    export default {
        name: 'CalcPriceSection',
        components: { CartSection, CRButton, CRRadio, CRCheckbox, CRTag, PriceBlock },
        mixins: [calculateMealKitPiceMixin],
        emits: ['openMealKitPage'],
        computed: {
            totalPriceWithoutDiscount() {
                return getMealKitAllPrices(this.form).totalPriceWithoutDiscount;
            },
        },
        methods: {
            getAdditionName(addition) {
                return `${this.$filters.getTranslationByKey(addition.name)} (${this.getAdditionPrice(
                    addition.price,
                    this.form.period.amount
                )}€)`;
            },

            handelToggle(item, field) {
                if (this.checkIfObjectAddedInArray(field, item)) {
                    this.form[field] = this.form[field].filter((elem) => elem.id !== item.id);
                } else {
                    this.form[field].push(item);
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    .calc-price-section {
        padding: 15px;

        .title-block {
            @include row-align-center-justify-between;

            padding-bottom: 10px;

            border-bottom: 1px solid $grey-line;

            .name {
                font-size: $font-20;
            }
        }

        .cart-section {
            @include column-align-center-justify-between;
            padding: 10px 0 0;

            .btn__wrapper {
                @include row-align-center-justify-around;
                flex-wrap: wrap;

                max-width: 280px;
            }

            .radio {
                margin-right: 10px;
                margin-bottom: 10px;
            }

            &.additions-section {
                &:deep(h5) {
                    margin-bottom: 0px;
                }

                p {
                    font-style: italic;
                    font-size: $font-14;

                    margin-bottom: 18px;
                }
            }
        }

        .price-block {
            @include column-align-center-justify-between;

            padding-top: 10px;

            &:deep(.price__wrapper) {
                @include column-align-center-justify-between;
            }
        }
    }
</style>
